<template>
  <div class="guide">
    <Normaltop
      :url="qiniuUrl"
      title="证书发放"
      en="Certificate issuance"
    ></Normaltop>

    <div class="guide-cont">
      <!-- <ul class="guide-menu flex">
        <li @click="goAssignBlock('block0',50)">证书介绍</li>
        <li @click="goAssignBlock('block1',50)">查看电子证书</li>
        <li @click="goAssignBlock('block2',50)">认证机构</li>
      </ul> -->
      <div class="bg-part" ref="block0">
        <div class="part">
          <h2 class="part-title">证书介绍</h2>
          <el-row :gutter="40" align="middle" type="flex">
            <el-col :span="7">
              <img src="../assets/images/certs/img1.jpg" alt srcset />
            </el-col>
            <el-col :span="17">
              <el-card>
                <div class="flex-row-between flex-col-center">
                  <div>
                    <p class="part-cont">
                      参与评测的人员，通过了“教学综合能力评测”、“网培政策规范评测”以及“教学数字化能力评测”三个模块的评测后，即可获得由中国商业联合会颁发的“网培师证书”，并可同时申请进入合作教培机构的教师储备人才库。申报高级网培师的人员，其申报资料经过认证中心的组织的专家评审后，将获得“高级网培师”证书。
                      <br />本项目并与联合会训研所UNITAR中国中心建立协作，网培师证书获得者可另外提出申请并支付一定评审费后，可获得由联合国训研所中国中心颁发的在线教育教师训练合格证书，并进入联合国训研所全球人才库。
                    </p>
                  </div>
                  <img src="../assets/images/certs/img2.png" alt />
                </div>
              </el-card>
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="part" ref="block1">
        <div class="flex-col-center flex-row-between">
          <div>
            <div>
              <h2 class="part-title">查看电子证书</h2>
              <p class="part-cont">
                网培师评测认证中心与EDC教育培训学习银行合作，在颁发纸质证书的同时也给网培师颁发基于教育区块链记录的电子证书。区块链电子证书与纸质证书既有同等效力，并具有数据安全可信、不可篡改等特点，且便于保存和转发。用户可以扫描二维码进入学习银行小程序，查看和转发自己的网培师电子证书。
              </p>
            </div>

            <div class="spe-color">
              <h2>扫描小程序二维码查看</h2>
              <p class="part-desc">
                <img src="../assets/images/certs/jt.png" alt srcset />
              </p>
            </div>
          </div>
          <img style="width:350px;" src="../assets/images/certs/img3.png" alt />
        </div>
      </div>
      <div ref="block2">
        <div class="part">
          <h2 class="part-title">认证机构</h2>
          <p class="part-cont">
            网培师是在国内推进在线教育发展及人社部推出在线教育相关新职业岗位的背景下推出的职业岗位，经上海市培训协会、上海人才服务行业协会、上海现代服务业联合会互联网科创专委会共同批准发起，经中国商业联合会批准设立的水平评价类资格证书。
          </p>

          <p class="part-cont">
            网培师能力评测认证中心负责进行面向广大的在线教育培训从业人员提供网络培训能力的测评认证，项目采用网班教育的《网培师教育培训及评测标准》体系。项目平台并与上海市人才服务行业协会一千多家企业会员单位等头部教培机构建立人才库的合作，面向国内的在线教育培训工作者提供技能培训、评测认证、上岗就业等系列服务。
          </p>
          <img
            style="width:90%;margin:30px auto 50px;display:block;"
            src="../assets/images/certs/logo.png"
            alt
          />
          <el-row :gutter="100">
            <el-col :span="12">
              <h3 class="part-h3">发证机构介绍：</h3>
              <p class="part-cont jgname">中国商业联合会</p>
              <p class="part-cont">
                中国商业联合会（英文：CHINA GENERAL CHAMBER OF
                COMMERCE，缩写CGCC）是1994年经民政部注册登记的具有社团法人资格的全国性行业组织。中国商业联合会前身是中华人民共和国商业部（后为国家国内贸易部），是中国商业流通领域的行业管理协会，原国家商业部、国家国内贸易部副部长何济海担任首任会长。
                本会的登记管理机关是民政部，党建领导机关是国务院国有资产监督管理委员会党委。
              </p>
              <p class="part-cont jgname">上海市培训协会</p>
              <p class="part-cont">
                上海市培训协会是由上海市开展培训领域相关服务的机构自愿组成，实行行业服务和自律管理的非营利性行业组织。协会由华东师范大学、上海开放大学、上海市师资培训中心、上海市教育评估协会、上海市民办教育协会、上海市青少年体育协会，以及上海精锐教育培训有限公司、上海昂立教育培训有限公司等多家单位共同发起。协会宗旨：通过规范开展活动，发挥桥梁作用，服务协调、引导促进、监督指导本市相关公办与民办机构规范发展，加强机构自律和行业自律、促进培训市场健康发展。
              </p>
              <!-- <p class="part-cont">上海人才服务行业协会</p>
              <p class="part-cont">
                上海人才服务行业协会成立于2002年4月9日，为上海市人力资源服务机构行业企事业单位自愿组成的跨部门、跨所有制的非营利的行业性社会团体法人。协会秘书处下设：办公室、会员服务部、培训服务部、商务投资部、研究发展部以及包括派遣、法务、咨询等在内的15个专业小组。服务包括人力资源管理咨询、高级人才寻访、人力资源服务外包、人力资源派遣、人才培训、测评、网络招聘、招聘会等，覆盖了国际、亚太、中国、区域、本土五个商圈，是上海率先完成国际化发展的行业，已经成为了上海现代服务业中的支柱产业。
              </p> -->
            </el-col>
            <el-col :span="11">
              <h3 class="part-h3">项目联合发起：</h3>
              <p class="part-p-m">上海市培训协会</p>
              <p class="part-p-m">上海人才服务行业协会</p>
              <p class="part-p-m">上海现代服务业联合会互联网科创服务专委会</p>
              <p class="part-p">上海网班教育科技股份有限公司</p>
              <h3 class="part-h3">全国项目设立：</h3>
              <p class="part-p">中国商业联合会</p>
              <h3 class="part-h3">项目《网培师培训及评测认证标准》制定：</h3>
              <p class="part-p-m">网培师评测标准专家组</p>
              <p class="part-p-m">上海人才服务行业协会</p>
              <p class="part-p">上海现代服务业联合会互联网科创服务专委会</p>
              <h3 class="part-h3">项目执行：</h3>
              <p class="part-p">网班培训师认证服务中心</p>
              <h3 class="part-h3">技术支持:</h3>
              <p class="part-p">上海网班教育科技股份有限公司</p>
              <h3 class="part-h3">项目合作机构：</h3>
              <p class="part-p">
                上海市信息化青年人才协会、上海市信息化企业家协会、上海信息服务行业协会，以及国内各大主流教育培训机构。
              </p>
              <h3 class="part-h3">特别合作机构：</h3>
              <p class="part-p">
                联合国训练研究所中国中心
              </p>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Normaltop from '../components/normaltop'
export default {
  name: 'CertInfo',
  components: { Normaltop },
  data() {
    return {
      qiniuUrl: this.$qiniuUrl + 'certs/banner.jpg',
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {},
    goAssignBlock(el, speed) {
      let windowH = window.innerHeight //浏览器窗口高度
      let h = this.$refs[el].offsetHeight //模块内容高度
      let t = this.$refs[el].offsetTop //模块相对于内容顶部的距离
      let top = t - (windowH - h) / 2 + 200 //需要滚动到的位置，若改为 t 则滚动到模块顶部位置，此处是滚动到模块相对于窗口垂直居中的位置
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop //滚动条距离顶部高度
      let currentTop = scrollTop //默认滚动位置为当前滚动条位置，若改为0，则每次都会从顶部滚动到指定位置
      let requestId
      //采用requestAnimationFrame，平滑动画
      function step() {
        //判断让滚动条向上滚还是向下滚
        if (scrollTop < top) {
          if (currentTop <= top) {
            //   window.scrollTo(x,y) y为上下滚动位置
            window.scrollTo(0, currentTop)
            requestId = window.requestAnimationFrame(step)
          } else {
            window.cancelAnimationFrame(requestId)
          }
          //向下滚动
          currentTop += speed
        } else {
          if (top <= currentTop) {
            //注：此处 - speed 是解决居中时存在的问题，可自行设置或去掉
            window.scrollTo(0, currentTop - speed)
            requestId = window.requestAnimationFrame(step)
          } else {
            window.cancelAnimationFrame(requestId)
          }
          //向上滚动
          currentTop -= speed
        }
      }
      window.requestAnimationFrame(step)
    },
    submitForm() {},
  },
}
</script>
<style lang="less" scoped>
.guide {
  font-weight: normal;
  font-size: 16px;
  img {
    width: 100%;
  }
  .guide-cont {
    position: relative;
    box-sizing: border-box;
    padding: 70px 0;
    .guide-menu {
      width: 70%;
      min-width: 1100px;
      margin: 0 auto;
      height: 35px;
      li {
        margin-right: 30px;
        font-size: 24px;
        color: #000005;
        cursor: pointer;
        &:hover {
          border-bottom: 2px solid #f5002e;
        }
      }
    }
    .part {
      width: 60%;
      min-width: 1100px;
      margin: 0 auto;
      position: relative;
      padding: 50px 0 10px;
      .methods {
        padding: 25px 0;
      }
      .part-title {
        color: #000005;
        font-weight: bold;
        font-size: 36px;
        padding-bottom: 40px;
      }
      .spe-color {
        color: #7c8087;
        font-size: 30px;
        h2 {
          font-weight: normal !important;
        }
        padding-top: 200px;
      }
      .part-h3 {
        font-size: 20px;
        font-weight: bold;
        color: #333333;
        padding-bottom: 15px;
      }
      .part-cont {
        padding-bottom: 16px;
        color: #333;
        line-height: 2;
      }
      .jgname {
        font-size: 17px;
        font-weight: bold;
      }
      .part-p {
        padding-bottom: 35px;
        color: #333;
      }
      .part-p-m {
        padding-bottom: 10px;
        color: #333;
      }
      .part-desc {
        color: #7c8087;
        font-size: 24px;
        img {
          width: 160px;
          margin: 20px 0 !important;
        }
      }
      .part-indent {
        text-indent: 20px;
      }

      .flex-row-between {
        img {
          margin-left: 40px;
        }
      }
      /deep/.el-card .el-card__body {
        padding: 70px 30px 60px 50px;
      }
    }
    .bg-part {
      width: 100%;
      background: url('../assets/images/certs/bg.png') no-repeat;
    }
  }
}
</style>
